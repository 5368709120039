<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What should you do when you hear a fire alarm during lab? Put the following steps in order.
        Any steps that aren't required can be left in the list of "Available items to sort".
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="optionsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="First"
        append-text="Last"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMPL3Q5',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        'Turn off equipment such as burners or heaters',
        'Close the fume hood sash',
        'Check that others are leaving the lab',
        'Leave the lab',
        'Take the stairs to the main floor, then exit the building',
        'Meet your lab group at a safe distance from the building',
        'Lock the lab door',
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
